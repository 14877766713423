
import React, { useEffect, useState, useMemo } from 'react'
import styles from './ProductInfo.module.css'
import AddToCartButton from '../../blocks/AddToCart/AddToCartButton'
import { getProduct } from '@lib/shopify/storefront-data-hooks/src/api/operations'
import shopifyConfig from '@lib/shopify/storefront-data-hooks/src/api/config'
import { prepareVariantsWithOptions } from '@lib/shopify/storefront-data-hooks/src/utils/product'

interface ProductInfoProps {
  productId: string;
  productName: string;
  productPrice: string;
  productDescription: string;
}

interface ProductOptionValue {
  value: string
}

interface ProductOption {
  name: string
  values: ProductOptionValue[]
}

interface VariantWithOptions {
  id: string
  color?: string
  priceV2: {
    amount: string
  }
}

export default function ProductInfo({ productId }: ProductInfoProps) {
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState<ShopifyBuy.Product | null>(null)
  const [selectedColor, setSelectedColor] = useState<string | null>(null)
  const [selectedVariantId, setSelectedVariantId] = useState<string | null>(null)

  useEffect(() => {
    async function fetchProduct() {
      setLoading(true)
      try {
        const fetchedProduct = await getProduct(shopifyConfig, { id: productId })
        setProduct(fetchedProduct)
        const availableColors = fetchedProduct.options.find(
          (option: ProductOption) => option.name.toLowerCase() === 'color'
        )?.values.map((v: ProductOptionValue) => v.value)
        if (availableColors) {
          setSelectedColor(availableColors.includes('White') ? 'White' : availableColors[0])
        }
      } catch (err) {
        console.error(err)
      }
      setLoading(false)
    }
    fetchProduct()
  }, [productId])

  const colorOptions = useMemo(() => {
    if (!product) return []
    const colorOption = product.options.find(
      (option: ProductOption) => option.name.toLowerCase() === 'color'
    )
    return colorOption?.values?.map((val: ProductOptionValue) => val.value) || []
  }, [product])

  const variants = useMemo(() => {
    if (!product) return []
    return prepareVariantsWithOptions(product.variants) as VariantWithOptions[]
  }, [product])

  useEffect(() => {
    if (!selectedColor || !variants.length) return
    const matchingVariant = variants.find(
      (v: VariantWithOptions) => v.color?.toLowerCase() === selectedColor.toLowerCase()
    )
    setSelectedVariantId(matchingVariant?.id || null)
  }, [selectedColor, variants])

  if (loading) return <div>Loading product...</div>
  if (!product) return <div>No product found.</div>

  return (
    <div className={styles.infoContainer}>
      <h1 className={styles.productName}>{product.title}</h1>
      <p className={styles.price}>
        {selectedVariantId
          ? `${variants.find((v: VariantWithOptions) => v.id === selectedVariantId)?.priceV2.amount} USD`
          : `${variants[0]?.priceV2.amount} USD`}
      </p>

      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: product.description || '' }}
      />
      {colorOptions.length > 0 && (
        <div style={{ display: 'flex', gap: 8, marginBottom: 16 }}>
          {colorOptions.map((color: string) => (
            <div
              key={color}
              onClick={() => setSelectedColor(color)}
              style={{
                width: 30,
                height: 30,
                borderRadius: '50%',
                backgroundColor: color.toLowerCase(),
                cursor: 'pointer',
                border: selectedColor === color ? '3px solid grey' : '1px solid gray',
              }}
              title={color}
            />
          ))}
        </div>
      )}

<AddToCartButton
  productId={String(product.id)}
  variantId={selectedVariantId || undefined}
  text={`Add to Cart ${selectedColor ? `(${selectedColor}) ` : ''}`}
/>


      {/* <AddToCartButton
        productId={product.id}
        variantId={selectedVariantId || undefined}
        text={`Add to Cart ${selectedColor ? `(${selectedColor}) ` : ''}`}
      /> */}
    </div>
  )
}
// import React, { useEffect, useState, useMemo } from 'react'
// import styles from './ProductInfo.module.css'
// import AddToCartButton from '../../blocks/AddToCart/AddToCartButton'
// import { getProduct } from '@lib/shopify/storefront-data-hooks/src/api/operations'
// import shopifyConfig from '@lib/shopify/storefront-data-hooks/src/api/config'
// import { prepareVariantsWithOptions } from '@lib/shopify/storefront-data-hooks/src/utils/product'

// interface ProductInfoProps {
//   productId: string
// }

// export default function ProductInfo({ productId }: ProductInfoProps) {
//   const [loading, setLoading] = useState(false)
//   const [product, setProduct] = useState<ShopifyBuy.Product | null>(null)
//   const [selectedColor, setSelectedColor] = useState<string | null>(null)
//   const [selectedVariantId, setSelectedVariantId] = useState<string | null>(null)

//   useEffect(() => {
//     async function fetchProduct() {
//       setLoading(true)
//       try {
//         const fetchedProduct = await getProduct(shopifyConfig, { id: productId })
//         setProduct(fetchedProduct)
//         // default color is white if available, otherwise first available color
//         const availableColors = fetchedProduct.options.find(
//           (option) => option.name.toLowerCase() === 'color'
//         )?.values.map((v) => v.value)
//         if (availableColors) {
//           setSelectedColor(availableColors.includes('White') ? 'White' : availableColors[0])
//         }
//       } catch (err) {
//         console.error(err)
//       }
//       setLoading(false)
//     }
//     fetchProduct()
//   }, [productId])

//   const colorOptions = useMemo(() => {
//     if (!product) return []
//     const colorOption = product.options.find(
//       (option) => option.name.toLowerCase() === 'color'
//     )
//     return colorOption?.values?.map((val) => val.value) || []
//   }, [product])

//   const variants = useMemo(() => {
//     if (!product) return []
//     return prepareVariantsWithOptions(product.variants)
//   }, [product])

//   useEffect(() => {
//     if (!selectedColor || !variants.length) return
//     const matchingVariant = variants.find(
//       (v: any) => v.color?.toLowerCase() === selectedColor.toLowerCase()
//     )
//     setSelectedVariantId(matchingVariant?.id || null)
//   }, [selectedColor, variants])

//   if (loading) return <div>Loading product...</div>
//   if (!product) return <div>No product found.</div>

//   return (
//     <div className={styles.infoContainer}>
//       <h1 className={styles.productName}>{product.title}</h1>
//       <p className={styles.price}>
//         {selectedVariantId
//           ? `${variants.find((v: any) => v.id === selectedVariantId)?.priceV2.amount} USD`
//           : `${variants[0]?.priceV2.amount} USD`}
//       </p>

//       <div
//         className={styles.description}
//         dangerouslySetInnerHTML={{ __html: product.descriptionHtml || '' }}
//       />
//  {colorOptions.length > 0 && (
//         <div style={{ display: 'flex', gap: 8, marginBottom: 16 }}>
//           {colorOptions.map((color) => (
//             <div
//               key={color}
//               onClick={() => setSelectedColor(color)}
//               style={{
//                 width: 30,
//                 height: 30,
//                 borderRadius: '50%',
//                 backgroundColor: color.toLowerCase(),
//                 cursor: 'pointer',
//                 border: selectedColor === color ? '3px solid grey' : '1px solid gray',
//               }}
//               title={color}
//             />
//           ))}
//         </div>
//       )}
//       <AddToCartButton
//         productId={product.id}
//         variantId={selectedVariantId || undefined}
//         text={`Add to Cart ${selectedColor ? `(${selectedColor}) ` : ''}`}
//       />
//     </div>
//   )
// }

// // import React, { useEffect, useState, useMemo } from 'react'
// // import styles from './ProductInfo.module.css'
// // import AddToCartButton from '../../blocks/AddToCart/AddToCartButton'
// // import { getProduct } from '@lib/shopify/storefront-data-hooks/src/api/operations'
// // import { prepareVariantsWithOptions } from '@lib/shopify/storefront-data-hooks/src/utils/product'

// // interface ProductInfoProps {
// //   productId: string
// // }

// // export default function ProductInfo({ productId }: ProductInfoProps) {
// //   const [loading, setLoading] = useState(false)
// //   const [product, setProduct] = useState<ShopifyBuy.Product | null>(null)

// //   // Store selected color and variant ID
// //   const [selectedColor, setSelectedColor] = useState<string | null>(null)
// //   const [selectedVariantId, setSelectedVariantId] = useState<string | null>(null)

// //   // Fetch product data by ID
// //   useEffect(() => {
// //     async function fetchProduct() {
// //       setLoading(true)
// //       try {
// //         const fetchedProduct = await getProduct({}, { id: productId })
// //         setProduct(fetchedProduct)
// //       } catch (err) {
// //         console.error(err)
// //       }
// //       setLoading(false)
// //     }
// //     fetchProduct()
// //   }, [productId])

// //   // Grab color options from the product
// //   const colorOptions = useMemo(() => {
// //     if (!product) return []
// //     const colorOption = product.options.find(
// //       (option) => option.name.toLowerCase() === 'color'
// //     )
// //     return colorOption?.values?.map((val) => val.value) || []
// //   }, [product])

// //   // Prepare variants with top-level color keys
// //   const variants = useMemo(() => {
// //     if (!product) return []
// //     return prepareVariantsWithOptions(product.variants)
// //   }, [product])

// //   // Update selected variant ID when the color changes
// //   useEffect(() => {
// //     if (!selectedColor || !variants.length) return
// //     const matchingVariant = variants.find(
// //       (v: any) => v.color === selectedColor
// //     )
// //     setSelectedVariantId(matchingVariant?.id || null)
// //   }, [selectedColor, variants])

// //   if (loading) return <div>Loading product...</div>
// //   if (!product) return <div>No product found.</div>

// //   return (
// //     <div className={styles.infoContainer}>
// //       <h1 className={styles.productName}>{product.title}</h1>
// //       {colorOptions.length > 0 && (
// //         <div style={{ display: 'flex', gap: 8, marginBottom: 16 }}>
// //           {colorOptions.map((color) => (
// //             <div
// //               key={color}
// //               onClick={() => setSelectedColor(color)}
// //               style={{
// //                 width: 30,
// //                 height: 30,
// //                 borderRadius: '50%',
// //                 backgroundColor: color,
// //                 cursor: 'pointer',
// //                 border: selectedColor === color ? '2px solid black' : '1px solid gray',
// //               }}
// //             />
// //           ))}
// //         </div>
// //       )}

// //       <p className={styles.price}>
// //         {/* Show price of the currently selected variant or the first variant */}
// //         {selectedVariantId
// //           ? `${
// //               variants.find((v: any) => v.id === selectedVariantId)?.priceV2.amount
// //             } USD`
// //           : `${variants[0]?.priceV2.amount} USD`}
// //       </p>

// //       <div className={styles.description} dangerouslySetInnerHTML={{ __html: product.descriptionHtml || '' }} />

// //       <AddToCartButton
// //         productId={product.id}
// //         variantId={selectedVariantId || undefined}
// //         text="Add to Cart"
// //       />
// //     </div>
// //   )
// // }

// // import React from 'react';
// // import styles from './ProductInfo.module.css';
// // import AddToCartButton from '../../blocks/AddToCart/AddToCartButton';

// // interface ProductInfoProps {
// //   productName: string;
// //   productPrice: string;
// //   productDescription: string;
// //   productId: string;
// // }

// // const ProductInfo: React.FC<ProductInfoProps> = ({ productName, productPrice, productDescription, productId }) => {
// //   return (
// //     <div className={styles.infoContainer}>
// //       <h1 className={styles.productName}>{productName}</h1>
// //       <p className={styles.price}>{productPrice}</p>
// //       <p className={styles.description}>{productDescription}</p>
// //       <AddToCartButton productId={productId} />
// //       {/* <button className={styles.quoteButton}>Contact For A Quote</button> */}
// //     </div>
// //   );
// // };

// // export default ProductInfo;