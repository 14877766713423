import React, { useState, ChangeEvent, FormEvent } from 'react';
import styles from './Form.module.css';

interface FormProps {
  isDarkMode?: boolean;
}

const useForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    interestedIn: '',
    message: '',
  });

  const [submitted, setSubmitted] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const newData = {
        ...prevData,
        [name]: value,
      };
      
      // Update form validity whenever data changes
      setIsValid(
        newData.firstName.trim() !== '' &&
        newData.lastName.trim() !== '' &&
        newData.emailAddress.trim() !== '' &&
        newData.phoneNumber.trim() !== '' &&
        newData.interestedIn !== '' &&
        newData.message.trim() !== ''
      );
      
      return newData;
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/submit-contact-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log(data.message);
      setSubmitted(true);
      setFormData({
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        interestedIn: '',
        message: '',
      });
      setIsValid(false);
    } catch (error) {
      console.error('Error:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  return { formData, submitted, handleChange, handleSubmit, isValid };
};

const FormG: React.FC<FormProps> = ({ isDarkMode = false }): JSX.Element => {
  const { formData, submitted, handleChange, handleSubmit, isValid } = useForm();
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  
  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isValid) {
      handleSubmit(e);
      setShowModal(true);
      setShowError(false);
    } else {
      setShowError(true);
    }
  };
  
  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  const handleClickSubmit = () => {
    if (isValid) {
      const formElement = document.querySelector('form');
      if (formElement) {
        formElement.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
    } else {
      setShowError(true);
    }
  };

  const themeClass = isDarkMode ? styles.darkMode : styles.lightMode;

  return (
    <form onSubmit={handleFormSubmit} className={`${styles.form} ${themeClass}`}>
      <div className={styles.row}>
        <div className={styles.column}>
          <input 
            className={styles.input} 
            name="firstName" 
            placeholder="First Name" 
            type="text" 
            value={formData.firstName} 
            onChange={handleChange} 
            required 
          />
          <div className={styles.line} />
        </div>
        <div className={styles.column}>
          <input 
            className={styles.input} 
            name="lastName" 
            placeholder="Last Name" 
            type="text" 
            value={formData.lastName} 
            onChange={handleChange} 
            required 
          />
          <div className={styles.line} />
        </div>
      </div>
      
      <div className={styles.row}>
        <div className={styles.column}>
          <input 
            className={styles.input} 
            name="emailAddress" 
            placeholder="Email Address" 
            type="email" 
            value={formData.emailAddress} 
            onChange={handleChange} 
            required 
          />
          <div className={styles.line} />
        </div>
        <div className={styles.column}>
          <input 
            className={styles.input} 
            name="phoneNumber" 
            placeholder="Phone Number" 
            type="tel" 
            value={formData.phoneNumber} 
            onChange={handleChange} 
            required 
          />
          <div className={styles.line} />
        </div>
      </div>
      
      <div className={styles.fullWidth}>
        <select 
          className={styles.select} 
          name="interestedIn" 
          value={formData.interestedIn} 
          onChange={handleChange} 
          required
        >
          <option value="">Interested In?</option>
          <option value="Sales">Sales</option>
          <option value="Starting a Subscription">Starting a Subscription</option>
          <option value="IPM Systems & Solutions">IPM Systems & Solutions</option>
          <option value="General Questions & Support">General Questions & Support</option>
        </select>
        <div className={styles.line} />
      </div>
      
      <div className={styles.fullWidth}>
        <textarea 
          className={styles.textarea} 
          name="message" 
          placeholder="Message:" 
          value={formData.message} 
          onChange={handleChange} 
          required 
        />
        <div className={styles.line} />
      </div>
      
      {showError && (
        <div className={styles.errorMessage}>
          Please fill out all fields before submitting.
        </div>
      )}
      
      <div 
        className={`${styles.submitButton} ${!isValid ? styles.submitButtonDisabled : ''}`} 
        onClick={handleClickSubmit}
      >
        <div className={styles.submitText}>SUBMIT</div>
        <div className={styles.submitArrow}>
          {/* <img src="/icon.svg" alt="Submit" /> */}
        </div>
      </div>

      {showModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h2>Thank you for your submission!</h2>
            <p>We will get back to you soon.</p>
            <button onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      )}
    </form>
  );
}

export default FormG;

// import React, { useState, ChangeEvent, FormEvent } from 'react';
// import styles from './Form.module.css';

// interface FormProps {
//   isDarkMode?: boolean;
// }

// const useForm = () => {
//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     emailAddress: '',
//     phoneNumber: '',
//     interestedIn: '',
//     message: '',
//   });

//   const [submitted, setSubmitted] = useState(false);

//   const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     try {
//       const response = await fetch('/api/submit-contact-form', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(formData),
//       });

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const data = await response.json();
//       console.log(data.message);
//       setSubmitted(true);
//       setFormData({
//         firstName: '',
//         lastName: '',
//         emailAddress: '',
//         phoneNumber: '',
//         interestedIn: '',
//         message: '',
//       });
//     } catch (error) {
//       console.error('Error:', error);
//       // Handle error (e.g., show error message to user)
//     }
//   };

//   const isFormValid = () => {
//     return (
//       formData.firstName.trim() !== '' &&
//       formData.lastName.trim() !== '' &&
//       formData.emailAddress.trim() !== '' &&
//       formData.phoneNumber.trim() !== '' &&
//       formData.interestedIn !== '' &&
//       formData.message.trim() !== ''
//     );
//   };

//   return { formData, submitted, handleChange, handleSubmit };
// };



// const FormG: React.FC<FormProps> = ({ isDarkMode = false }): JSX.Element => {

//   const { formData, submitted, handleChange, handleSubmit, isFormValid } = useForm();
//   const [showModal, setShowModal] = useState(false);
//   const [showError, setShowError] = useState(false);
  
//   const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     if (isFormValid()) {
//       handleSubmit(e);
//       setShowModal(true);
//       setShowError(false);
//     } else {
//       setShowError(true);
//     }
//   };
  
//   const handleClickSubmit = () => {
//     if (isFormValid()) {
//       const formElement = document.querySelector('form');
//       if (formElement) {
//         formElement.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
//       }
//     } else {
//       setShowError(true);
//     }
//   };
// //   const { formData, submitted, handleChange, handleSubmit } = useForm();
// //   const [showModal, setShowModal] = useState(false);
  
// //   const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
// //     handleSubmit(e);
// //     setShowModal(true);
// //   };
  
// //   const handleCloseModal = () => {
// //     setShowModal(false);
// //   };
  
// //   const handleClickSubmit = () => {
// //     const formElement = document.querySelector('form');
// //     if (formElement) {
// //       formElement.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
// //     }
// //   };

//   const themeClass = isDarkMode ? styles.darkMode : styles.lightMode;

//   return (
//     <form onSubmit={handleFormSubmit} className={`${styles.form} ${themeClass}`}>
//       <div className={styles.row}>
//         <div className={styles.column}>
//           <input className={styles.input} name="firstName" placeholder="First Name" type="text" value={formData.firstName} onChange={handleChange} required />
//           <div className={styles.line} />
//         </div>
//         <div className={styles.column}>
//           <input className={styles.input} name="lastName" placeholder="Last Name" type="text" value={formData.lastName} onChange={handleChange} required />
//           <div className={styles.line} />
//         </div>
//       </div>
      
//       <div className={styles.row}>
//         <div className={styles.column}>
//           <input className={styles.input} name="emailAddress" placeholder="Email Address" type="email" value={formData.emailAddress} onChange={handleChange} required />
//           <div className={styles.line} />
//         </div>
//         <div className={styles.column}>
//           <input className={styles.input} name="phoneNumber" placeholder="Phone Number" type="tel" value={formData.phoneNumber} onChange={handleChange} required />
//           <div className={styles.line} />
//         </div>
//       </div>
      
//       <div className={styles.fullWidth}>
//         <select className={styles.select} name="interestedIn" value={formData.interestedIn} onChange={handleChange} required>
//           <option value="">Interested In?</option>
//           <option value="Sales">Sales</option>
//           <option value="Starting a Subscription">Starting a Subscription</option>
//           <option value="IPM Systems & Solutions">IPM Systems & Solutions</option>
//           <option value="General Questions & Support">General Questions & Support</option>
//         </select>
//         <div className={styles.line} />
//       </div>
      
//       <div className={styles.fullWidth}>
//         <textarea className={styles.textarea} name="message" placeholder="Message:" value={formData.message} onChange={handleChange} required />
//         <div className={styles.line} />
//       </div>

//       {showError && (
//         <div className={styles.errorMessage}>
//           Please fill out all fields before submitting.
//         </div>
//       )}

// <div 
//         className={`${styles.submitButton} ${!isFormValid() ? styles.submitButtonDisabled : ''}`} 
//         onClick={handleClickSubmit}
//       >
//         <div className={styles.submitText}>SUBMIT</div>
//         <div className={styles.submitArrow}>
//           {/* <img src="/icon.svg" alt="Submit" /> */}
//         </div>
//       </div>
      

//       {/* <div className={styles.submitButton} onClick={handleClickSubmit}>
//         <div className={styles.submitText}>SUBMIT</div>
//         <div className={styles.submitArrow}>
//           // <img src="/icon.svg" alt="Submit" /> //
//         </div>
//       </div> */}

//       {showModal && (
//         <div className={styles.modal}>
//           <div className={styles.modalContent}>
//             <h2>Thank you for your submission!</h2>
//             <p>We will get back to you soon.</p>
//             <button onClick={handleCloseModal}>Close</button>
//           </div>
//         </div>
//       )}
//     </form>
//   );
// }

// export default FormG;




// // import React, { useState, ChangeEvent, FormEvent } from 'react';
// // import styles from './Form.module.css';

// // interface FormProps {
// //   isDarkMode?: boolean;
// // }

// // const useForm = () => {
// //   const [formData, setFormData] = useState({
// //     firstName: '',
// //     lastName: '',
// //     emailAddress: '',
// //     phoneNumber: '',
// //     interestedIn: '',
// //     message: '',
// //   });

// //   const [submitted, setSubmitted] = useState(false);

// //   const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
// //     const { name, value } = e.target;
// //     setFormData((prevData) => ({
// //       ...prevData,
// //       [name]: value,
// //     }));
// //   };

// //   const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
// //     e.preventDefault();
// //     setSubmitted(true);
// //     setFormData({
// //       firstName: '',
// //       lastName: '',
// //       emailAddress: '',
// //       phoneNumber: '',
// //       interestedIn: '',
// //       message: '',
// //     });
// //   };

// //   return { formData, submitted, handleChange, handleSubmit };
// // };

// // const FormG: React.FC<FormProps> = ({ isDarkMode = false }): JSX.Element => {
// //   const { formData, submitted, handleChange, handleSubmit } = useForm();
// //   const [showModal, setShowModal] = useState(false);
  
// //   const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
// //     handleSubmit(e);
// //     setShowModal(true);
// //   };
  
// //   const handleCloseModal = () => {
// //     setShowModal(false);
// //   };
  
// //   const handleClickSubmit = () => {
// //     const formElement = document.querySelector('form');
// //     if (formElement) {
// //       formElement.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
// //     }
// //     setShowModal(true);
// //   };

// //   const themeClass = isDarkMode ? styles.darkMode : styles.lightMode;

// //   return (
// //     <form onSubmit={handleFormSubmit} className={`${styles.form} ${themeClass}`}>
// //       <div className={styles.row}>
// //         <div className={styles.column}>
// //           <input className={styles.input} name="firstName" placeholder="First Name" type="text" value={formData.firstName} onChange={handleChange} />
// //           <div className={styles.line} />
// //         </div>
// //         <div className={styles.column}>
// //           <input className={styles.input} name="lastName" placeholder="Last Name" type="text" value={formData.lastName} onChange={handleChange} />
// //           <div className={styles.line} />
// //         </div>
// //       </div>
      
// //       <div className={styles.row}>
// //         <div className={styles.column}>
// //           <input className={styles.input} name="emailAddress" placeholder="Email Address" type="email" value={formData.emailAddress} onChange={handleChange} />
// //           <div className={styles.line} />
// //         </div>
// //         <div className={styles.column}>
// //           <input className={styles.input} name="phoneNumber" placeholder="Phone Number" type="tel" value={formData.phoneNumber} onChange={handleChange} />
// //           <div className={styles.line} />
// //         </div>
// //       </div>
      
// //       <div className={styles.fullWidth}>
// //         <select className={styles.select} name="interestedIn" value={formData.interestedIn} onChange={handleChange}>
// //           <option value="">Interested In?</option>
// //           <option value="option1">Sales</option>
// //           <option value="option2">Starting a Subscription</option>
// //           <option value="option3">IPM Systems & Solutions</option>
// //           <option value="option4">General Questions & Support</option>
// //         </select>
// //         <div className={styles.line} />
// //       </div>
      
// //       <div className={styles.fullWidth}>
// //         <textarea className={styles.textarea} name="message" placeholder="Message:" value={formData.message} onChange={handleChange} />
// //         <div className={styles.line} />
// //       </div>
      
// //       <div className={styles.submitButton} onClick={handleClickSubmit}>
// //         <div className={styles.submitText}>SUBMIT</div>
// //         <div className={styles.submitArrow}>
// //           {/* <img src="/icon.svg" alt="Submit" /> */}
// //         </div>
// //       </div>
// //     </form>
// //   );
// // }

// // export default FormG;
// // // import React, { useState, ChangeEvent, FormEvent } from 'react';
// // // import Link from "next/link";

// // // import Modal from './components/Modal';
// // // // import './components/modal.css';

// // // import styles from './Form.module.css';
// // // const useForm = () => {
// // //   const [formData, setFormData] = useState({
// // //     firstName: '',
// // //     lastName: '',
// // //     emailAddress: '',
// // //     phoneNumber: '',
// // //     interestedIn: '',
// // //     message: '',
// // //   });

// // //   const [submitted, setSubmitted] = useState(false);

// // //   const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
// // //     const { name, value } = e.target;
// // //     setFormData((prevData) => ({
// // //       ...prevData,
// // //       [name]: value,
// // //     }));
// // //   };

// // //   const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
// // //     e.preventDefault();
// // //     setSubmitted(true);
// // //     setFormData({
// // //       firstName: '',
// // //       lastName: '',
// // //       emailAddress: '',
// // //       phoneNumber: '',
// // //       interestedIn: '',
// // //       message: '',
// // //     });
// // //   };

// // //   return { formData, submitted, handleChange, handleSubmit };
// // // };

// // // const FormG = (): JSX.Element => {
// // //   const { formData, submitted, handleChange, handleSubmit } = useForm();
// // //   const [showModal, setShowModal] = useState(false);
  
// // //   const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
// // //     handleSubmit(e);
// // //     setShowModal(true);
// // //   };
  
// // //   const handleCloseModal = () => {
// // //     setShowModal(false);
// // //   };
  
// // //   const handleClickSubmit = () => {
// // //     const formElement = document.querySelector('form');
// // //     if (formElement) {
// // //       formElement.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
// // //     }
// // //     setShowModal(true);
// // //   };

// // //   return (
// // //     <form onSubmit={handleFormSubmit} className={styles.form}>
     
// // //       <input className={styles.input} name="firstName" placeholder="First Name" type="text" value={formData.firstName} onChange={handleChange} />
// // //       <div className={styles.line} />
// // //       <input className={styles.input} name="lastName" placeholder="Last Name" type="text" value={formData.lastName} onChange={handleChange} />
// // //       <div className={styles.line} />
// // //       <input className={styles.input} name="emailAddress" placeholder="Email Address" type="email" value={formData.emailAddress} onChange={handleChange} />
// // //       <div className={styles.line} />
// // //       <input className={styles.input} name="phoneNumber" placeholder="Phone Number" type="tel" value={formData.phoneNumber} onChange={handleChange} />
// // //       <div className={styles.line} />
// // //       <select className={styles.select} name="interestedIn" value={formData.interestedIn} onChange={handleChange}>
// // //         <option value="">Interested In?</option>
// // //         <option value="na1">N/A</option>
// // //         <option value="na2">N/A</option>
// // //         <option value="na3">N/A</option>
// // //       </select>
// // //       <div className={styles.line} />
// // //       <textarea className={styles.textarea} name="message" placeholder="Message" value={formData.message} onChange={handleChange} />
// // //       <div className={styles.line} />
// // //       <Modal show={showModal} handleClose={handleCloseModal} />
// // //       <div className={styles.submitButton} onClick={handleClickSubmit}>
// // //         <img className={styles.submitArrow} alt="Arrow" src="/img/arrow-11.svg" />
// // //         <div className={styles.submitText}>SUBMIT</div>
// // //       </div>
// // //     </form>
// // //   );

// // // }
// // // //   return (
// // // //     <form onSubmit={handleFormSubmit}>
// // // //       <div className="group-38" onClick={handleClickSubmit}>
// // // //         <img className="arrow-3" alt="Arrow" src="/img/arrow-11.svg" />
// // // //         <div className="submit text-wrapper-27">SUBMIT</div>
// // // //       </div>
// // // //       <input className="first-name" name="firstName" placeholder="First Name" type="text" value={formData.firstName} onChange={handleChange} />
// // // //       <input className="last-name" name="lastName" placeholder="Last Name" type="text" value={formData.lastName} onChange={handleChange} />
// // // //       <input className="email-address" name="emailAddress" placeholder="Email Address" type="email" value={formData.emailAddress} onChange={handleChange} />
// // // //       <input className="phone-number" name="phoneNumber" placeholder="Phone Number" type="tel" value={formData.phoneNumber} onChange={handleChange} />
// // // //       <select className="interested-in" name="interestedIn" value={formData.interestedIn} onChange={handleChange}>
// // // //         <option value="">Interested In?</option>
// // // //         <option value="na1">N/A</option>
// // // //         <option value="na2">N/A</option>
// // // //         <option value="na3">N/A</option>
// // // //       </select>
// // // //       <textarea className="message" name="message" placeholder="Message" value={formData.message} onChange={handleChange} />
// // // //       <Modal show={showModal} handleClose={handleCloseModal} />
// // // //     </form>
// // // //   );
// // // // };

// // // export default FormG;
