import React, { useState, ChangeEvent, FormEvent } from 'react';
import Link from "next/link";

import Modal from './components/Modal';
import stylesDark from './Form_Mobile.module.css';
import stylesLight from './Form_Mobile_White.module.css';

interface FormGProps {
  isDarkMode?: boolean;
}

interface FormData {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  interestedIn: string;
  message: string;
}

interface FormErrors {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  interestedIn?: string;
  message?: string;
}

const useForm = () => {
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    interestedIn: '',
    message: '',
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [submitted, setSubmitted] = useState(false);

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    
    // Name validations
    if (!formData.firstName.trim()) {
      newErrors.firstName = 'First name is required';
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = 'Last name is required';
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.emailAddress.trim()) {
      newErrors.emailAddress = 'Email is required';
    } else if (!emailRegex.test(formData.emailAddress)) {
      newErrors.emailAddress = 'Please enter a valid email address';
    }

    // Phone validation
    const phoneRegex = /^\+?[\d\s-]{10,}$/;
    if (!formData.phoneNumber.trim()) {
      newErrors.phoneNumber = 'Phone number is required';
    } else if (!phoneRegex.test(formData.phoneNumber)) {
      newErrors.phoneNumber = 'Please enter a valid phone number';
    }

    // Interest validation
    if (!formData.interestedIn) {
      newErrors.interestedIn = 'Please select an interest';
    }

    // Message validation
    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Clear error when user starts typing
    if (errors[name as keyof FormErrors]) {
      setErrors(prev => ({
        ...prev,
        [name]: undefined
      }));
    }
  };

  const handleSubmit = async (data: FormData) => {
    if (!validateForm()) {
      return false;
    }

    try {
      const response = await fetch('/api/submit-contact-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      console.log(responseData.message);
      setSubmitted(true);
      setFormData({
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        interestedIn: '',
        message: '',
      });
      return true;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  };

  return { formData, errors, submitted, handleChange, handleSubmit };
};

const FormG: React.FC<FormGProps> = ({ isDarkMode = false }): JSX.Element => {
  const { formData, errors, submitted, handleChange, handleSubmit } = useForm();
  const [showModal, setShowModal] = useState(false);
  
  const styles = isDarkMode ? stylesDark : stylesLight;
  
  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const success = await handleSubmit(formData);
    if (success) {
      setShowModal(true);
    }
  };
  
  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  const handleClickSubmit = async () => {
    const success = await handleSubmit(formData);
    if (success) {
      setShowModal(true);
    }
  };

  return (
    <form onSubmit={handleFormSubmit} className={styles.form}>
      <div className={styles.inputWrapper}>
        <input 
          className={`${styles.input} ${errors.firstName ? styles.error : ''}`}
          name="firstName" 
          placeholder="First Name" 
          type="text" 
          value={formData.firstName} 
          onChange={handleChange} 
          required 
        />
        {errors.firstName && <span className={styles.errorText}>{errors.firstName}</span>}
      </div>
      <div className={styles.line} />

      <div className={styles.inputWrapper}>
        <input 
          className={`${styles.input} ${errors.lastName ? styles.error : ''}`}
          name="lastName" 
          placeholder="Last Name" 
          type="text" 
          value={formData.lastName} 
          onChange={handleChange} 
          required 
        />
        {errors.lastName && <span className={styles.errorText}>{errors.lastName}</span>}
      </div>
      <div className={styles.line} />

      <div className={styles.inputWrapper}>
        <input 
          className={`${styles.input} ${errors.emailAddress ? styles.error : ''}`}
          name="emailAddress" 
          placeholder="Email Address" 
          type="email" 
          value={formData.emailAddress} 
          onChange={handleChange} 
          required 
        />
        {errors.emailAddress && <span className={styles.errorText}>{errors.emailAddress}</span>}
      </div>
      <div className={styles.line} />

      <div className={styles.inputWrapper}>
        <input 
          className={`${styles.input} ${errors.phoneNumber ? styles.error : ''}`}
          name="phoneNumber" 
          placeholder="Phone Number" 
          type="tel" 
          value={formData.phoneNumber} 
          onChange={handleChange} 
          required 
        />
        {errors.phoneNumber && <span className={styles.errorText}>{errors.phoneNumber}</span>}
      </div>
      <div className={styles.line} />

      <div className={styles.inputWrapper}>
        <select 
          className={`${styles.select} ${errors.interestedIn ? styles.error : ''}`}
          name="interestedIn" 
          value={formData.interestedIn} 
          onChange={handleChange} 
          required
        >
          <option value="">Interested In?</option>
          <option value="Sales">Sales</option>
          <option value="Starting a Subscription">Starting a Subscription</option>
          <option value="IPM Systems & Solutions">IPM Systems & Solutions</option>
          <option value="General Questions & Support">General Questions & Support</option>
        </select>
        {errors.interestedIn && <span className={styles.errorText}>{errors.interestedIn}</span>}
      </div>
      <div className={styles.line} />

      <div className={styles.inputWrapper}>
        <textarea 
          className={`${styles.textarea} ${errors.message ? styles.error : ''}`}
          name="message" 
          placeholder="Message" 
          value={formData.message} 
          onChange={handleChange} 
          required 
        />
        {errors.message && <span className={styles.errorText}>{errors.message}</span>}
      </div>
      <div className={styles.line} />
      
      <Modal show={showModal} handleClose={handleCloseModal} />
      <div className={styles.submitButton} onClick={handleClickSubmit}>
        <img 
          className={styles.submitArrow} 
          alt="Arrow" 
          src={isDarkMode ? "/icon_dark.svg" : "/icon.svg"} 
        />
        <div className={styles.submitText}>SUBMIT</div>
      </div>
    </form>
  );
}

export default FormG;

// import React, { useState, ChangeEvent, FormEvent } from 'react';
// import Link from "next/link";

// import Modal from './components/Modal';
// import stylesDark from './Form_Mobile.module.css';
// import stylesLight from './Form_Mobile_White.module.css';

// interface FormGProps {
//   isDarkMode?: boolean;
// }

// interface FormData {
//   firstName: string;
//   lastName: string;
//   emailAddress: string;
//   phoneNumber: string;
//   interestedIn: string;
//   message: string;
// }

// const useForm = () => {
//   const [formData, setFormData] = useState<FormData>({
//     firstName: '',
//     lastName: '',
//     emailAddress: '',
//     phoneNumber: '',
//     interestedIn: '',
//     message: '',
//   });

//   const [submitted, setSubmitted] = useState(false);

//   const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (data: FormData) => {
//     try {
//       const response = await fetch('/api/submit-contact-form', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(data),
//       });

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const responseData = await response.json();
//       console.log(responseData.message);
//       setSubmitted(true);
//       setFormData({
//         firstName: '',
//         lastName: '',
//         emailAddress: '',
//         phoneNumber: '',
//         interestedIn: '',
//         message: '',
//       });
//       return true;
//     } catch (error) {
//       console.error('Error:', error);
//       return false;
//     }
//   };

//   return { formData, submitted, handleChange, handleSubmit };
// };

// const FormG: React.FC<FormGProps> = ({ isDarkMode = false }): JSX.Element => {
//   const { formData, submitted, handleChange, handleSubmit } = useForm();
//   const [showModal, setShowModal] = useState(false);
  
//   const styles = isDarkMode ? stylesDark : stylesLight;
  
//   const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     const success = await handleSubmit(formData);
//     if (success) {
//       setShowModal(true);
//     }
//   };
  
//   const handleCloseModal = () => {
//     setShowModal(false);
//   };
  
//   const handleClickSubmit = async () => {
//     const success = await handleSubmit(formData);
//     if (success) {
//       setShowModal(true);
//     }
//   };

//   return (
//     <form onSubmit={handleFormSubmit} className={styles.form}>
//       <input 
//         className={styles.input} 
//         name="firstName" 
//         placeholder="First Name" 
//         type="text" 
//         value={formData.firstName} 
//         onChange={handleChange} 
//         required 
//       />
//       <div className={styles.line} />
//       <input 
//         className={styles.input} 
//         name="lastName" 
//         placeholder="Last Name" 
//         type="text" 
//         value={formData.lastName} 
//         onChange={handleChange} 
//         required 
//       />
//       <div className={styles.line} />
//       <input 
//         className={styles.input} 
//         name="emailAddress" 
//         placeholder="Email Address" 
//         type="email" 
//         value={formData.emailAddress} 
//         onChange={handleChange} 
//         required 
//       />
//       <div className={styles.line} />
//       <input 
//         className={styles.input} 
//         name="phoneNumber" 
//         placeholder="Phone Number" 
//         type="tel" 
//         value={formData.phoneNumber} 
//         onChange={handleChange} 
//         required 
//       />
//       <div className={styles.line} />
//       <select 
//         className={styles.select} 
//         name="interestedIn" 
//         value={formData.interestedIn} 
//         onChange={handleChange} 
//         required
//       >
//         <option value="">Interested In?</option>
//         <option value="Sales">Sales</option>
//         <option value="Starting a Subscription">Starting a Subscription</option>
//         <option value="IPM Systems & Solutions">IPM Systems & Solutions</option>
//         <option value="General Questions & Support">General Questions & Support</option>
//       </select>
//       <div className={styles.line} />
//       <textarea 
//         className={styles.textarea} 
//         name="message" 
//         placeholder="Message" 
//         value={formData.message} 
//         onChange={handleChange} 
//         required 
//       />
//       <div className={styles.line} />
//       <Modal show={showModal} handleClose={handleCloseModal} />
//       <div className={styles.submitButton} onClick={handleClickSubmit}>
//         <img 
//           className={styles.submitArrow} 
//           alt="Arrow" 
//           src={isDarkMode ? "/icon_dark.svg" : "/icon.svg"} 
//         />
//         <div className={styles.submitText}>SUBMIT</div>
//       </div>
//     </form>
//   );
// }

// export default FormG;


// //works but circular data reference

// // import React, { useState, ChangeEvent, FormEvent } from 'react';
// // import Link from "next/link";

// // import Modal from './components/Modal';
// // import stylesDark from './Form_Mobile.module.css';
// // import stylesLight from './Form_Mobile_White.module.css';

// // interface FormGProps {
// //   isDarkMode?: boolean;
// // }

// // const useForm = () => {
// //   const [formData, setFormData] = useState({
// //     firstName: '',
// //     lastName: '',
// //     emailAddress: '',
// //     phoneNumber: '',
// //     interestedIn: '',
// //     message: '',
// //   });

// //   const [submitted, setSubmitted] = useState(false);

// //   const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
// //     const { name, value } = e.target;
// //     setFormData((prevData) => ({
// //       ...prevData,
// //       [name]: value,
// //     }));
// //   };

// //   const handleSubmit = async (formData: typeof formData) => {
// //     try {
// //       const response = await fetch('/api/submit-contact-form', {
// //         method: 'POST',
// //         headers: {
// //           'Content-Type': 'application/json',
// //         },
// //         body: JSON.stringify(formData),
// //       });

// //       if (!response.ok) {
// //         throw new Error('Network response was not ok');
// //       }

// //       const data = await response.json();
// //       console.log(data.message);
// //       setSubmitted(true);
// //       setFormData({
// //         firstName: '',
// //         lastName: '',
// //         emailAddress: '',
// //         phoneNumber: '',
// //         interestedIn: '',
// //         message: '',
// //       });
// //       return true;
// //     } catch (error) {
// //       console.error('Error:', error);
// //       return false;
// //     }
// //   };

// //   return { formData, submitted, handleChange, handleSubmit };
// // };

// // const FormG: React.FC<FormGProps> = ({ isDarkMode = false }): JSX.Element => {
// //   const { formData, submitted, handleChange, handleSubmit } = useForm();
// //   const [showModal, setShowModal] = useState(false);
  
// //   const styles = isDarkMode ? stylesDark : stylesLight;
  
// //   const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
// //     e.preventDefault();
// //     const success = await handleSubmit(formData);
// //     if (success) {
// //       setShowModal(true);
// //     }
// //   };
  
// //   const handleCloseModal = () => {
// //     setShowModal(false);
// //   };
  
// //   const handleClickSubmit = async () => {
// //     const success = await handleSubmit(formData);
// //     if (success) {
// //       setShowModal(true);
// //     }
// //   };

// //   return (
// //     <form onSubmit={handleFormSubmit} className={styles.form}>
// //       <input 
// //         className={styles.input} 
// //         name="firstName" 
// //         placeholder="First Name" 
// //         type="text" 
// //         value={formData.firstName} 
// //         onChange={handleChange} 
// //         required 
// //       />
// //       <div className={styles.line} />
// //       <input 
// //         className={styles.input} 
// //         name="lastName" 
// //         placeholder="Last Name" 
// //         type="text" 
// //         value={formData.lastName} 
// //         onChange={handleChange} 
// //         required 
// //       />
// //       <div className={styles.line} />
// //       <input 
// //         className={styles.input} 
// //         name="emailAddress" 
// //         placeholder="Email Address" 
// //         type="email" 
// //         value={formData.emailAddress} 
// //         onChange={handleChange} 
// //         required 
// //       />
// //       <div className={styles.line} />
// //       <input 
// //         className={styles.input} 
// //         name="phoneNumber" 
// //         placeholder="Phone Number" 
// //         type="tel" 
// //         value={formData.phoneNumber} 
// //         onChange={handleChange} 
// //         required 
// //       />
// //       <div className={styles.line} />
// //       <select 
// //         className={styles.select} 
// //         name="interestedIn" 
// //         value={formData.interestedIn} 
// //         onChange={handleChange} 
// //         required
// //       >
// //         <option value="">Interested In?</option>
// //         <option value="Sales">Sales</option>
// //         <option value="Starting a Subscription">Starting a Subscription</option>
// //         <option value="IPM Systems & Solutions">IPM Systems & Solutions</option>
// //         <option value="General Questions & Support">General Questions & Support</option>
// //       </select>
// //       <div className={styles.line} />
// //       <textarea 
// //         className={styles.textarea} 
// //         name="message" 
// //         placeholder="Message" 
// //         value={formData.message} 
// //         onChange={handleChange} 
// //         required 
// //       />
// //       <div className={styles.line} />
// //       <Modal show={showModal} handleClose={handleCloseModal} />
// //       <div className={styles.submitButton} onClick={handleClickSubmit}>
// //         <img 
// //           className={styles.submitArrow} 
// //           alt="Arrow" 
// //           src={isDarkMode ? "/icon_dark.svg" : "/icon.svg"} 
// //         />
// //         <div className={styles.submitText}>SUBMIT</div>
// //       </div>
// //     </form>
// //   );
// // }

// // export default FormG;




// // import React, { useState, ChangeEvent, FormEvent } from 'react';
// // import Link from "next/link";

// // import Modal from './components/Modal';
// // import styles from './Form_Mobile.module.css';

// // const useForm = () => {
// //   const [formData, setFormData] = useState({
// //     firstName: '',
// //     lastName: '',
// //     emailAddress: '',
// //     phoneNumber: '',
// //     interestedIn: '',
// //     message: '',
// //   });

// //   const [submitted, setSubmitted] = useState(false);

// //   const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
// //     const { name, value } = e.target;
// //     setFormData((prevData) => ({
// //       ...prevData,
// //       [name]: value,
// //     }));
// //   };

// //   const handleSubmit = async (formData: typeof formData) => {
// //     try {
// //       const response = await fetch('/api/submit-contact-form', {
// //         method: 'POST',
// //         headers: {
// //           'Content-Type': 'application/json',
// //         },
// //         body: JSON.stringify(formData),
// //       });

// //       if (!response.ok) {
// //         throw new Error('Network response was not ok');
// //       }

// //       const data = await response.json();
// //       console.log(data.message);
// //       setSubmitted(true);
// //       setFormData({
// //         firstName: '',
// //         lastName: '',
// //         emailAddress: '',
// //         phoneNumber: '',
// //         interestedIn: '',
// //         message: '',
// //       });
// //       return true;
// //     } catch (error) {
// //       console.error('Error:', error);
// //       return false;
// //     }
// //   };

// //   return { formData, submitted, handleChange, handleSubmit };
// // };

// // const FormG = (): JSX.Element => {
// //   const { formData, submitted, handleChange, handleSubmit } = useForm();
// //   const [showModal, setShowModal] = useState(false);
  
// //   const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
// //     e.preventDefault();
// //     const success = await handleSubmit(formData);
// //     if (success) {
// //       setShowModal(true);
// //     }
// //   };
  
// //   const handleCloseModal = () => {
// //     setShowModal(false);
// //   };
  
// //   const handleClickSubmit = async () => {
// //     const success = await handleSubmit(formData);
// //     if (success) {
// //       setShowModal(true);
// //     }
// //   };

// //   return (
// //     <form onSubmit={handleFormSubmit} className={styles.form}>
// //       <input className={styles.input} name="firstName" placeholder="First Name" type="text" value={formData.firstName} onChange={handleChange} required />
// //       <div className={styles.line} />
// //       <input className={styles.input} name="lastName" placeholder="Last Name" type="text" value={formData.lastName} onChange={handleChange} required />
// //       <div className={styles.line} />
// //       <input className={styles.input} name="emailAddress" placeholder="Email Address" type="email" value={formData.emailAddress} onChange={handleChange} required />
// //       <div className={styles.line} />
// //       <input className={styles.input} name="phoneNumber" placeholder="Phone Number" type="tel" value={formData.phoneNumber} onChange={handleChange} required />
// //       <div className={styles.line} />
// //       <select className={styles.select} name="interestedIn" value={formData.interestedIn} onChange={handleChange} required>
// //         <option value="">Interested In?</option>
// //         <option value="Sales">Sales</option>
// //         <option value="Starting a Subscription">Starting a Subscription</option>
// //         <option value="IPM Systems & Solutions">IPM Systems & Solutions</option>
// //         <option value="General Questions & Support">General Questions & Support</option>
// //       </select>
// //       <div className={styles.line} />
// //       <textarea className={styles.textarea} name="message" placeholder="Message" value={formData.message} onChange={handleChange} required />
// //       <div className={styles.line} />
// //       <Modal show={showModal} handleClose={handleCloseModal} />
// //       <div className={styles.submitButton} onClick={handleClickSubmit}>
// //         <img className={styles.submitArrow} alt="Arrow" src="/icon.svg" />
// //         <div className={styles.submitText}>SUBMIT</div>
// //       </div>
// //     </form>
// //   );
// // }

// // export default FormG;
// // import React, { useState, ChangeEvent, FormEvent } from 'react';
// // import Link from "next/link";

// // import Modal from './components/Modal';
// // // import './components/modal.css';

// // import styles from './Form_Mobile.module.css';

// // const useForm = () => {
// //   const [formData, setFormData] = useState({
// //     firstName: '',
// //     lastName: '',
// //     emailAddress: '',
// //     phoneNumber: '',
// //     interestedIn: '',
// //     message: '',
// //   });

// //   const [submitted, setSubmitted] = useState(false);

// //   const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
// //     const { name, value } = e.target;
// //     setFormData((prevData) => ({
// //       ...prevData,
// //       [name]: value,
// //     }));
// //   };

// //   const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
// //     e.preventDefault();
// //     try {
// //       const response = await fetch('/api/submit-contact-form', {
// //         method: 'POST',
// //         headers: {
// //           'Content-Type': 'application/json',
// //         },
// //         body: JSON.stringify(formData),
// //       });

// //       if (!response.ok) {
// //         throw new Error('Network response was not ok');
// //       }

// //       const data = await response.json();
// //       console.log(data.message);
// //       setSubmitted(true);
// //       setFormData({
// //         firstName: '',
// //         lastName: '',
// //         emailAddress: '',
// //         phoneNumber: '',
// //         interestedIn: '',
// //         message: '',
// //       });
// //     } catch (error) {
// //       console.error('Error:', error);
// //       // Handle error (e.g., show error message to user)
// //     }
// //   };

// //   return { formData, submitted, handleChange, handleSubmit };
// // };

// // const FormG = (): JSX.Element => {
// //   const { formData, submitted, handleChange, handleSubmit } = useForm();
// //   const [showModal, setShowModal] = useState(false);
  
// //   const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
// //     await handleSubmit(e);
// //     setShowModal(true);
// //   };
  
// //   const handleCloseModal = () => {
// //     setShowModal(false);
// //   };
  
// //   const handleClickSubmit = () => {
// //     const formElement = document.querySelector('form');
// //     if (formElement) {
// //       formElement.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
// //     }
// //   };

// //   return (
// //     <form onSubmit={handleFormSubmit} className={styles.form}>
     
// //       <input className={styles.input} name="firstName" placeholder="First Name" type="text" value={formData.firstName} onChange={handleChange} required />
// //       <div className={styles.line} />
// //       <input className={styles.input} name="lastName" placeholder="Last Name" type="text" value={formData.lastName} onChange={handleChange} required />
// //       <div className={styles.line} />
// //       <input className={styles.input} name="emailAddress" placeholder="Email Address" type="email" value={formData.emailAddress} onChange={handleChange} required />
// //       <div className={styles.line} />
// //       <input className={styles.input} name="phoneNumber" placeholder="Phone Number" type="tel" value={formData.phoneNumber} onChange={handleChange} required />
// //       <div className={styles.line} />
// //       <select className={styles.select} name="interestedIn" value={formData.interestedIn} onChange={handleChange} required>
// //         <option value="">Interested In?</option>
// //         <option value="Sales">Sales</option>
// //         <option value="Starting a Subscription">Starting a Subscription</option>
// //         <option value="IPM Systems & Solutions">IPM Systems & Solutions</option>
// //         <option value="General Questions & Support">General Questions & Support</option>
// //       </select>
// //       <div className={styles.line} />
// //       <textarea className={styles.textarea} name="message" placeholder="Message" value={formData.message} onChange={handleChange} required />
// //       <div className={styles.line} />
// //       <Modal show={showModal} handleClose={handleCloseModal} />
// //       <div className={styles.submitButton} onClick={handleClickSubmit}>
// //         <img className={styles.submitArrow} alt="Arrow" src="/icon_dark.svg" />
// //         <div className={styles.submitText}>SUBMIT</div>
// //       </div>
// //     </form>
// //   );
// // }

// // export default FormG;

// // import React, { useState, ChangeEvent, FormEvent } from 'react';
// // import Link from "next/link";

// // import Modal from './components/Modal';
// // // import './components/modal.css';

// // import styles from './Form_Mobile.module.css';
// // const useForm = () => {
// //   const [formData, setFormData] = useState({
// //     firstName: '',
// //     lastName: '',
// //     emailAddress: '',
// //     phoneNumber: '',
// //     interestedIn: '',
// //     message: '',
// //   });

// //   const [submitted, setSubmitted] = useState(false);

// //   const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
// //     const { name, value } = e.target;
// //     setFormData((prevData) => ({
// //       ...prevData,
// //       [name]: value,
// //     }));
// //   };

// //   const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
// //     e.preventDefault();
// //     setSubmitted(true);
// //     setFormData({
// //       firstName: '',
// //       lastName: '',
// //       emailAddress: '',
// //       phoneNumber: '',
// //       interestedIn: '',
// //       message: '',
// //     });
// //   };

// //   return { formData, submitted, handleChange, handleSubmit };
// // };

// // const FormG = (): JSX.Element => {
// //   const { formData, submitted, handleChange, handleSubmit } = useForm();
// //   const [showModal, setShowModal] = useState(false);
  
// //   const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
// //     handleSubmit(e);
// //     setShowModal(true);
// //   };
  
// //   const handleCloseModal = () => {
// //     setShowModal(false);
// //   };
  
// //   const handleClickSubmit = () => {
// //     const formElement = document.querySelector('form');
// //     if (formElement) {
// //       formElement.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
// //     }
// //     setShowModal(true);
// //   };

// //   return (
// //     <form onSubmit={handleFormSubmit} className={styles.form}>
     
// //       <input className={styles.input} name="firstName" placeholder="First Name" type="text" value={formData.firstName} onChange={handleChange} />
// //       <div className={styles.line} />
// //       <input className={styles.input} name="lastName" placeholder="Last Name" type="text" value={formData.lastName} onChange={handleChange} />
// //       <div className={styles.line} />
// //       <input className={styles.input} name="emailAddress" placeholder="Email Address" type="email" value={formData.emailAddress} onChange={handleChange} />
// //       <div className={styles.line} />
// //       <input className={styles.input} name="phoneNumber" placeholder="Phone Number" type="tel" value={formData.phoneNumber} onChange={handleChange} />
// //       <div className={styles.line} />
// //       <select className={styles.select} name="interestedIn" value={formData.interestedIn} onChange={handleChange}>
// //         <option value="">Interested In?</option>
// //         <option value="option1">Sales</option>
// //           <option value="option2">Starting a Subscription</option>
// //           <option value="option3">IPM Systems & Solutions</option>
// //           <option value="option4">General Questions & Support</option>
// //       </select>
// //       <div className={styles.line} />
// //       <textarea className={styles.textarea} name="message" placeholder="Message" value={formData.message} onChange={handleChange} />
// //       <div className={styles.line} />
// //       <Modal show={showModal} handleClose={handleCloseModal} />
// //       <div className={styles.submitButton} onClick={handleClickSubmit}>
// //         <img className={styles.submitArrow} alt="Arrow" src="/icon_dark.svg" />
// //         <div className={styles.submitText}>SUBMIT</div>
// //       </div>
// //     </form>
// //   );

// // }
// //   return (
// //     <form onSubmit={handleFormSubmit}>
// //       <div className="group-38" onClick={handleClickSubmit}>
// //         <img className="arrow-3" alt="Arrow" src="/img/arrow-11.svg" />
// //         <div className="submit text-wrapper-27">SUBMIT</div>
// //       </div>
// //       <input className="first-name" name="firstName" placeholder="First Name" type="text" value={formData.firstName} onChange={handleChange} />
// //       <input className="last-name" name="lastName" placeholder="Last Name" type="text" value={formData.lastName} onChange={handleChange} />
// //       <input className="email-address" name="emailAddress" placeholder="Email Address" type="email" value={formData.emailAddress} onChange={handleChange} />
// //       <input className="phone-number" name="phoneNumber" placeholder="Phone Number" type="tel" value={formData.phoneNumber} onChange={handleChange} />
// //       <select className="interested-in" name="interestedIn" value={formData.interestedIn} onChange={handleChange}>
// //         <option value="">Interested In?</option>
// //         <option value="na1">N/A</option>
// //         <option value="na2">N/A</option>
// //         <option value="na3">N/A</option>
// //       </select>
// //       <textarea className="message" name="message" placeholder="Message" value={formData.message} onChange={handleChange} />
// //       <Modal show={showModal} handleClose={handleCloseModal} />
// //     </form>
// //   );
// // };

// // export default FormG;
