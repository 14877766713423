import Image from 'next/image'
// import { Button } from '@/components/ui/button'
import { Button } from "@trycreo/ui/button";
import styles from './IPMGrid.module.css'

const products = [
  {
    id: 1,
    name: 'Neutra-Control A',
    sub: '(Preventative) 42Floz',
    price: 129.00,
    image: '/products/IPMSolutions/Neutrasolution.png',
    url: '/neutracontrol-a'

    // image: '/placeholder.svg?height=200&width=200'

  },
  {
    id: 2,
    name: 'Neutra-Control B',
    sub: '(Reactive) 42floz',
    price: 159.00,
    image: '/products/IPMSolutions/Neutrasolution.png',
    url: '/neutracontrol-b'

  },
  {
    id: 3,
    name: 'Neutra-Spore A',
    sub: '(Preventative) 42Floz',
    price: 109.00,
    image: '/products/IPMSolutions/Neutrasolution.png',
    url: '/neutraspore-a'

  },
  {
    id: 4,
    name: 'Neutra-Spore B',
    sub: '(Reactive) 42Floz',
    price: 129.00,
    image: '/products/IPMSolutions/Neutrasolution.png',
    url: '/neutraspore-b'

  },
  {
    id: 5,
    name: 'Aura Wetting',
    sub: 'Agent',
    price: 119.00,
    image: '/products/IPMSolutions/Aura-Solution.png',
    url: '/aura-wetting-agent'

  },
  {
    id: 6,
    name: 'Halo Wetting',
    sub: 'Agent',
    price: 119.00,
    image: '/products/IPMSolutions/Halo-Solution.png',
    url: '/halo-wetting-agent'

  },
  
  {
    id: 7,
    name: 'Neutra-HOCL',
    sub: 'Hypochlorous Acid, 10 count',
    price: 239.00,
    image: '/products/IPMSolutions/HOCL-Tablets.png',
    url: '/neutra-hocl'

  },
  {
    id: 8,
    name: 'Neutra-HOCL',
    sub: '3 Gallons, Agent',
    price: 35.00,
    image: '/products/IPMSolutions/Neutra_HOCL_3G_6G.png',
    url: '/neutra-hocl-3-g'

  },
  {
    id: 9,
    name: 'Neutra-HOCL',
    sub: '6 Gallons, Agent',
    price: 70.00,
    image: '/products/IPMSolutions/Neutra_HOCL_3G_6G.png',
    url: '/neutra-hocl-6-g'

  },
  {
    id: 10,
    name: 'Neutra-Purge',
    sub: 'Agent',
    price: 590.00,
    image: '/products/IPMSolutions/Neutra_Solution.png',
    url: '/neutra-purge'

  },
  {
    id: 11,
    name: 'Nano Neutra-Can',
    sub: 'Box of 6 Mix & Match',
    price: 634.00,
    image: '/products/IPMSolutions/Neutra-Can.png',
    url: '/neutra-can'

  },
  
  
]

export default function IPMGrid() {
  return (
    <div className={styles.grid}>
      {products.map((product) => (
        <div key={product.id} className={styles.product}>
          <div className={styles.imageContainer}>
            <Image
              src={product.image}
              alt={product.name}
              layout="fill"
              objectFit="contain"
            />
          </div>
          <h3 className={styles.productName}>{product.name}</h3>
          <h4 className={styles.productSub}>{product.sub}</h4>

          <p className={styles.productPrice}>${product.price.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>
          <Button 
          className={styles.addToCartButton}
          onClick={() => window.location.href = product.url}

          
          >
            View Product
          </Button>
        </div>
      ))}
    </div>
  )
}


// import Image from 'next/image'
// // import { Button } from '@/components/ui/button'
// import { Button } from "@trycreo/ui/button";
// import styles from './IPMGrid.module.css'

// const products = [
//   {
//     id: 1,
//     name: 'Neutra-Control A',
//     sub: '(Preventative) 42Floz',
//     price: 129.00,
//     image: '/products/IPMSolutions/Neutrasolution.png',
//     url: '/neutracontrol-a'

//     // image: '/placeholder.svg?height=200&width=200'

//   },
//   {
//     id: 2,
//     name: 'Neutra-Control B',
//     sub: '(Reactive) 42floz',
//     price: 159.00,
//     image: '/products/IPMSolutions/Neutrasolution.png',
//     url: '/neutracontrol-b'

//   },
//   {
//     id: 3,
//     name: 'Neutra-Spore A',
//     sub: '(Preventative) 42Floz',
//     price: 109.00,
//     image: '/products/IPMSolutions/Neutrasolution.png',
//     url: '/neutraspore-a'

//   },
//   {
//     id: 4,
//     name: 'Neutra-Spore B',
//     sub: '(Reactive) 42Floz',
//     price: 129.00,
//     image: '/products/IPMSolutions/Neutrasolution.png',
//     url: '/neutraspore-b'

//   },
//   {
//     id: 5,
//     name: 'Aura Wetting',
//     sub: 'Agent',
//     price: 119.00,
//     image: '/products/IPMSolutions/Aura-Solution.png',
//     url: '/aura-wetting-agent'

//   },
//   {
//     id: 6,
//     name: 'Halo Wetting',
//     sub: 'Agent',
//     price: 119.00,
//     image: '/products/IPMSolutions/Halo-Solution.png',
//     url: '/halo-wetting-agent'

//   },
  
//   {
//     id: 7,
//     name: 'Neutra-HOCL',
//     sub: 'Hypochlorous Acid, 10 count',
//     price: 239.00,
//     image: '/products/IPMSolutions/HOCL-Tablets.png',
//     url: '/neutra-hocl'

//   },
//   {
//     id: 8,
//     name: 'Neutra-HOCL',
//     sub: '3 Gallons, Agent',
//     price: 35.00,
//     image: '/products/IPMSolutions/Neutra_HOCL_3G_6G.png',
//     url: '/neutra-hocl-3-g'

//   },
//   {
//     id: 9,
//     name: 'Neutra-HOCL',
//     sub: '6 Gallons, Agent',
//     price: 70.00,
//     image: '/products/IPMSolutions/Neutra_HOCL_3G_6G.png',
//     url: '/neutra-hocl-6-g'

//   },
//   {
//     id: 10,
//     name: 'Neutra-Purge',
//     sub: 'Agent',
//     price: 590.00,
//     image: '/products/IPMSolutions/Neutra_Solution.png',
//     url: '/neutra-purge'

//   },
  
  
// ]

// export default function IPMGrid() {
//   return (
//     <div className={styles.grid}>
//       {products.map((product) => (
//         <div key={product.id} className={styles.product}>
//           <div className={styles.imageContainer}>
//             <Image
//               src={product.image}
//               alt={product.name}
//               layout="fill"
//               objectFit="contain"
//             />
//           </div>
//           <h3 className={styles.productName}>{product.name}</h3>
//           <h4 className={styles.productSub}>{product.sub}</h4>

//           <p className={styles.productPrice}>${product.price.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>
//           <Button 
//           className={styles.addToCartButton}
//           onClick={() => window.location.href = product.url}

          
//           >
//             View Product
//           </Button>
//         </div>
//       ))}
//     </div>
//   )
// }