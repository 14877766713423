/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useEffect, useState, useMemo } from 'react'
import { Button } from '@theme-ui/components'
import { useAddItemToCart } from '@lib/shopify/storefront-data-hooks'
import { getProduct } from '@lib/shopify/storefront-data-hooks/src/api/operations'
import shopifyConfig from '@config/shopify'
import { prepareVariantsWithOptions } from '@lib/shopify/storefront-data-hooks/src/utils/product'

interface ProductPurchaseBlockProps {
  productId: string
}

export const ProductPurchaseBlock = ({ productId }: ProductPurchaseBlockProps) => {
  const [product, setProduct] = useState<ShopifyBuy.Product | null>(null)
  const [loadingProduct, setLoadingProduct] = useState(false)
  const [selectedColor, setSelectedColor] = useState<string | null>(null)
  const [selectedVariantId, setSelectedVariantId] = useState<string | null>(null)
  const addItem = useAddItemToCart()

  
  const [adding, setAdding] = useState(false)

  useEffect(() => {
    async function loadProduct() {
      setLoadingProduct(true)
      try {
        const fetchedProduct = await getProduct(shopifyConfig, { id: productId })
        setProduct(fetchedProduct)
        const colorOption = fetchedProduct.options.find(
          (opt: ShopifyBuy.Option) => opt.name.toLowerCase() === 'color'
        )
        const colorValues = colorOption?.values.map((v: ShopifyBuy.OptionValue) => v.value) ?? []
        // const colorValues = colorOption?.values.map((v) => v.value) ?? []
        if (colorValues.length) {
          setSelectedColor(colorValues.includes('White') ? 'White' : colorValues[0])
        }
      } catch (err) {
        console.error(err)
      }
      setLoadingProduct(false)
    }
    loadProduct()
  }, [productId])

  const variants = useMemo(() => {
    if (!product) return []
    return prepareVariantsWithOptions(product.variants)
  }, [product])

  useEffect(() => {
    if (!selectedColor || !variants.length) return
    const matchedVariant = variants.find(
      (v: any) => v.color?.toLowerCase() === selectedColor.toLowerCase()
    )
    setSelectedVariantId(matchedVariant?.id || null)
  }, [selectedColor, variants])

  const handleAddToCart = async () => {
    if (!selectedVariantId) return
    setAdding(true)
    try {
      await addItem(selectedVariantId, 1)
    } catch (err) {
      console.error(err)
    }
    setAdding(false)
  }

  if (loadingProduct || !product) return <div>Loading...</div>

  const colorOptions =
    product.options.find((opt: ShopifyBuy.Option) => opt.name.toLowerCase() === 'color')?.values.map(
      (v) => v.value
    ) ?? []

  return (
    <div sx={{ display: 'flex', flexDirection: 'column', gap: 3, ml:2, mr:2 }}>
      {/* <h2 sx={{ mb: 2 }}>{product.title}</h2> */}
      {colorOptions.length > 0 && (
        <div sx={{ display: 'flex', gap: 2, mb: 3 }}>
          {colorOptions.map((color: string) => (
            <div
              key={color}
              onClick={() => setSelectedColor(color)}
              title={color}
              sx={{
                width: 30,
                height: 30,
                borderRadius: '50%',
                cursor: 'pointer',
                bg: color.toLowerCase(),
                border: selectedColor === color ? '2px solid black' : '1px solid #ccc',
              }}
            />
          ))}
        </div>
      )}
      <Button onClick={handleAddToCart} disabled={adding || !selectedVariantId}>
        {adding ? 'Adding...' : `Add to Cart ${selectedColor ? `(${selectedColor}) ` : ''}`}
      </Button>
    </div>
  )
}

export default ProductPurchaseBlock

// /** @jsxRuntime classic */
// /** @jsx jsx */
// import { jsx } from 'theme-ui'
// import { useEffect, useState, useMemo } from 'react'
// import { Button } from '@theme-ui/components'
// import { useAddItemToCart } from '@lib/shopify/storefront-data-hooks'
// import { getProduct } from '@lib/shopify/storefront-data-hooks/src/api/operations'
// import shopifyConfig from '@config/shopify'
// import { prepareVariantsWithOptions } from '@lib/shopify/storefront-data-hooks/src/utils/product'


// interface ProductPurchaseBlockProps {
//   productId: string
// }

// export const ProductPurchaseBlock = ({ productId }: ProductPurchaseBlockProps) => {
//   const [product, setProduct] = useState<ShopifyBuy.Product | null>(null)
//   const [loadingProduct, setLoadingProduct] = useState(false)
//   const [selectedColor, setSelectedColor] = useState<string | null>(null)
//   const [selectedVariantId, setSelectedVariantId] = useState<string | null>(null)
//   const addItem = useAddItemToCart()
//   const [adding, setAdding] = useState(false)

//   useEffect(() => {
//     async function loadProduct() {
//       setLoadingProduct(true)
//       try {
//         const fetchedProduct = await getProduct(shopifyConfig, { id: productId })
//         setProduct(fetchedProduct)
//         const colorOption = fetchedProduct.options.find((opt) => opt.name.toLowerCase() === 'color')
//         if (colorOption) {
//           setSelectedColor(colorOption.values.includes('White') ? 'White' : colorOption.values[0])
//         }
//       } catch (err) {
//         console.error(err)
//       }
//       setLoadingProduct(false)
//     }
//     loadProduct()
//   }, [productId])

//   const variants = useMemo(() => {
//     if (!product) return []
//     return prepareVariantsWithOptions(product.variants)
//   }, [product])

//   useEffect(() => {
//     if (!selectedColor || !variants.length) return
//     const matchedVariant = variants.find((v: any) => v.color?.toLowerCase() === selectedColor.toLowerCase())
//     setSelectedVariantId(matchedVariant?.id || null)
//   }, [selectedColor, variants])

//   const handleAddToCart = async () => {
//     if (!selectedVariantId) return
//     setAdding(true)
//     try {
//       await addItem(selectedVariantId, 1)
//     } catch (err) {
//       console.error(err)
//     }
//     setAdding(false)
//   }

//   if (loadingProduct || !product) return <div>Loading...</div>

//   const colorOptions = product.options.find((opt) => opt.name.toLowerCase() === 'color')?.values || []

//   return (
//     <div sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
//       <h2 sx={{ mb: 2 }}>{product.title}</h2>
//       {colorOptions.length > 0 && (
//         <div sx={{ display: 'flex', gap: 2, mb: 3 }}>
//           {colorOptions.map((color) => (
//             <div
//               key={color}
//               onClick={() => setSelectedColor(color)}
//               title={color}
//               sx={{
//                 width: 30,
//                 height: 30,
//                 borderRadius: '50%',
//                 cursor: 'pointer',
//                 bg: color.toLowerCase(),
//                 border: selectedColor === color ? '2px solid black' : '1px solid #ccc',
//               }}
//             />
//           ))}
//         </div>
//       )}
//       <Button onClick={handleAddToCart} disabled={adding || !selectedVariantId}>
//         {adding ? 'Adding...' : `Add ${selectedColor ? `(${selectedColor}) ` : ''}to Cart`}
//       </Button>
//     </div>
//   )
// }

// export default ProductPurchaseBlock